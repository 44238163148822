import component from '@123/druid/dist/Framework/Decorators/Component';
import {inject, injectable} from '@123/druid/dist/Framework/Decorators/Inject';
import ApplicationConfig from '@Application/ApplicationConfig';
import MessageBus from '@Service/Message/MessageBus';
import type Scrollable from '@Service/Scrollable/Scrollable';
import QuickFilterHandler from './QuickFilterHandler';
import type AccessorInterface from '@123/druid/dist/Framework/View/AccessorInterface';
import type ViewAccessor from '@123/druid/dist/Framework/View/ViewAccessor';

@component
@injectable()
export default class QuickFilter implements AccessorInterface {
    constructor(
        @inject('WindowScroller') private windowScroller: Scrollable,
        @inject(ApplicationConfig) private applicationConfig: ApplicationConfig,
        @inject(MessageBus) private messageBus?: MessageBus,
    ) {}

    public setAccessor(accessor: ViewAccessor): void {
        new QuickFilterHandler(accessor, this.windowScroller, this.applicationConfig, this.messageBus).attach();
    }
}
